export const initialAsyncData = {
  completed: false,
  error: undefined
}

export const defaultOptions = {
  maxAsyncActionDuration: 0,
  serverOnly: true
}

export class AsyncActionController {
  isBrowser = typeof window !== 'undefined'
  asyncData = {}
  asyncPendingCount = 0
  asyncAllCount = 0
  asyncCompletePromise = Promise.resolve()
  asyncCompleteResolve = () => null

  constructor (options = {}) {
    this.options = Object.assign({}, defaultOptions, options)
  }

  createDefferPromise () {
    let deffer = {}
    deffer.promise = new Promise(resolve => {
      deffer.resolve = resolve
    })
    return deffer
  }

  createAsyncCompletePromise () {
    const deffer = this.createDefferPromise()
    this.asyncCompletePromise = deffer.promise
    this.asyncCompleteResolve = deffer.resolve
  }

  incrementCounter () {
    if (this.asyncPendingCount === 0) {
      this.createAsyncCompletePromise()
    }
    this.asyncAllCount++
    this.asyncPendingCount++
  }

  decrementCounter () {
    if (this.asyncPendingCount <= 0) {
      return
    }
    this.asyncPendingCount--
    if (this.asyncPendingCount === 0) {
      this.asyncCompleteResolve()
    }
  }

  createAsyncData (id) {
    this.asyncData[id] = { ...initialAsyncData, ...this.createDefferPromise() }
    // eslint-disable-next-line promise/catch-or-return
    this.asyncData[id].promise.then(() => this.decrementCounter())
    this.incrementCounter()
    if (this.options.maxAsyncActionDuration) {
      setTimeout(() => {
        if (!this.asyncData[id].completed) {
          const error = new Error('ServerReRenderController: max async action duration exceeded')
          this.completeAction(id, error)
        }
      }, this.options.maxAsyncActionDuration)
    }
  }

  checkAsyncData (id) {
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('ServerReRenderController: id must be not empty string or number')
    }
    if (!this.asyncData[id]) this.createAsyncData(id)
  }

  isDisabled () {
    return this.options.serverOnly && this.isBrowser
  }

  startAction (id) {
    if (this.isDisabled()) return
    this.checkAsyncData(id)
  }

  completeAction (id, error) {
    if (this.isDisabled()) return
    this.checkAsyncData(id)
    this.asyncData[id].completed = true
    if (error) this.asyncData[id].error = error
    this.asyncData[id].resolve()
  }

  completeAllActions () {
    for (const id of Object.keys(this.asyncData)) {
      this.completeAction(id)
    }
  }

  createActionInstance (id) {
    return {
      start: () => this.startAction(id),
      complete: error => this.completeAction(id, error)
    }
  }

  getCompletePromise () {
    return this.asyncCompletePromise
  }

  getActionPromise (id) {
    return this.asyncData[id] ? this.asyncData[id].promise : Promise.resolve()
  }

  hasAction (id) {
    return Boolean(this.asyncData[id])
  }

  hasIncompleteActions () {
    return this.asyncPendingCount !== 0
  }
}
