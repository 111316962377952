import { LOADING_DATA_ERROR, RUN_FUNCTION_WITH_STATE } from '../../index'
import { CLEAR_GLOBAL_ERROR, WRITE_GLOBAL_ERROR } from './constants'
import cleanSet from 'clean-set'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _pick from 'lodash/pick'

/**
 * @typedef {Object} RemoteDataProvider_new_props
 * [en|added by middleware props, which can be used in [RemoteDataProvider](#remote-data-provider.RemoteDataProvider)]
 * [ru|добавляемые middleware props, которые могут использоваться в [RemoteDataProvider](#remote-data-provider.RemoteDataProvider)]
 * @param {Array<String>} [exGlobalError]
 * [en|if truthy, errors from this RemoteDataProvider will be collected by globalErrorMiddleware in redux state]
 * [ru|если значение правдиво, ошибки с этого RemoteDataProvider будут собираться с помощью globalErrorMiddleware в redux state]
 * @memberOf extensions.globalError.globalErrorMiddleware
 */

/**
 * @desc
 * [en|redux middleware which collect errors (see [`payload.error` in response](#remote-data-provider.getData.LOADING_DATA_ERROR)) from all [RemoteDataProviders](#remote-data-provider.RemoteDataProvider), if to it are set parameters are necessary]
 * [ru|redux middleware которое собирает ошибки (см. [`payload.error` в ответе](#remote-data-provider.getData.LOADING_DATA_ERROR)) со всех [RemoteDataProvider](#remote-data-provider.RemoteDataProvider), если ему заданы необходимые props]
 * @param {Object} [options]
 * [en|usually, `options` need to be set only if there are crossings with other props keys]
 * [ru|обычно `options` нужно задавать только если имеются пересечения в названиях props]
 * @param {Boolean} [options.setByDefault=false]
 * [en|if [`exGlobalError`](extensions.globalError.globalErrorMiddleware.RemoteDataProvider_new_props) prop in RemoteDataProvider component not defined, this value is used as default]
 * [ru|если prop [`exGlobalError`](extensions.globalError.globalErrorMiddleware.RemoteDataProvider_new_props) в RemoteDataProvider компоненте не задан, этот параметр используется по умолчанию]
 * @param {String} [options.setErrorKey='exGlobalError']
 * [en|if defined, change key for RemoteDataProvider [`exGlobalError`](extensions.globalError.globalErrorMiddleware.RemoteDataProvider_new_props) prop]
 * [ru|если задан, меняет ключ для RemoteDataProvider [`exGlobalError`](extensions.globalError.globalErrorMiddleware.RemoteDataProvider_new_props) prop]
 * @param {String} [options.reduxKey='_globalError']
 * [en|redux key, on which errors will be collect in remoteData state (by defaults, `state.remoteData._globalError`]
 * [ru|ключ, по которому будут храниться ошибки в redux remoteData state (по умолчанию, это `state.remoteData._globalError`)]
 * @returns {Function} middleware
 * @memberOf extensions.globalError
 * @example
 import { globalErrorMiddleware } from 'remote-data-provider/extensions/globalError'

 const store = compose(
   applyMiddleware(globalErrorMiddleware()),
   // [[en|other middlewares][ru|остальные middlewares]]
 )(createStore)(rootReducer, initialState)

 // [[en|further in a code][ru|далее в коде]]

 <RemoteDataProvider /> // [[en|errors DON'T will be collected by globalErrorMiddleware][ru|ошибки НЕ будут собираться с помощью globalErrorMiddleware]]

 <RemoteDataProvider // [[en|errors will be collected by globalErrorMiddleware][ru|ошибки будут собираться с помощью globalErrorMiddleware]]
   exGlobalError={true}
 />

 // [[en|usage with custom params][ru|использование с измененными параметрами]]

 const store = compose(
   applyMiddleware(
     collectorMiddleware({
       setErrorKey: 'justError',
       reduxKey: 'justErrorsInRedux',
       setByDefault: true
     })
   ),
 // [[en|other middlewares][ru|остальные middlewares]]
 )(createStore)(rootReducer, initialState)

 // [[en|further in a code][ru|далее в коде]]

 <RemoteDataProvider /> // [[en|errors will be collected by globalErrorMiddleware][ru|ошибки будут собираться с помощью globalErrorMiddleware]]

 <RemoteDataProvider // [[en|errors will NOT be collected by globalErrorMiddleware][ru|ошибки НЕ будут собираться с помощью globalErrorMiddleware]]
   justError={false}
 />
 */
function globalErrorMiddleware (options = {}) {
  const {
    setErrorKey = 'exGlobalError',
    reduxKey = '_globalError',
    setByDefault = false
  } = options

  return store => next => action => {
    if (
      action.type === LOADING_DATA_ERROR &&
      _get(action, ['payload', setErrorKey], setByDefault) &&
      _has(action, ['payload', 'reducerKey']) &&
      _has(action, ['payload', 'error'])
    ) {
      next({
        type: RUN_FUNCTION_WITH_STATE,
        subtype: WRITE_GLOBAL_ERROR,
        payload: getFunctionAddError(reduxKey, action.payload)
      })
    } else if (
      action.type === CLEAR_GLOBAL_ERROR &&
      _get(action, 'key', reduxKey) === reduxKey
    ) {
      next({
        type: RUN_FUNCTION_WITH_STATE,
        subtype: CLEAR_GLOBAL_ERROR,
        payload: getFunctionClearErrors(reduxKey)
      })
    }
    return next(action)
  }
}

export { globalErrorMiddleware }

export const initialState = {
  errors: []
}

export const getFunctionAddError = (reduxKey, payload) => state => {
  const error = _pick(payload, ['error', 'reducerKey', 'reducerPath'])

  return cleanSet(
    state,
    [reduxKey, 'errors'],
    errors => errors ? [...errors, error] : [error]
  )
}

export const getFunctionClearErrors = reduxKey => state => cleanSet(state, reduxKey, initialState)
