import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import hoistNonReactStatics from 'hoist-non-react-statics'
import _assign from 'lodash/assign'
import _get from 'lodash/get'
import _has from 'lodash/has'

export const defaultOptions = {
  scrollOnPathChanges: true, // прокручивать страницу при изменении пути
  scrollOnHashChanges: false, // прокручивать страницу при изменении hash параметров
  scrollOnSearchChanges: false, // прокручивать страницу при изменении get параметров
  getComponentTop: false, // если true, положение объекта на странице складывается со значением top
  useOnMount: false, // прокручивать страницу при монтировании компонента
  top: 0, // позиция, к которой прокручивать страницу. если getComponentTop установлен в true, значение top добавляется к положению объекта
  scrollFunction: (top) => typeof window !== 'undefined' && window.scrollTo(0, top), // функция для скролла
  checkLocation: ({ location, match, prevLocation, prevMatch }) => _get(match, 'url') === _get(prevMatch, 'url') // проверка
}

export const contextTypes = {
  scrollTopSet: PropTypes.func,
  scrollTopRemove: PropTypes.func,
  scrollParent: PropTypes.bool
}

export function getOptions (options) {
  if (options == null) return defaultOptions
  if (typeof options === 'object') {
    return _assign({}, defaultOptions, options)
  } else {
    return _assign({}, defaultOptions, { scrollOnPathChanges: !!options })
  }
}

/**
 * create wrapped react component
 * @param {Options} [options]
 * @returns {function(ReactComponent: Function): React.Component}
 */
export default (options) => WrappedComponent => {
  options = getOptions(options)
  const {
    scrollOnPathChanges,
    scrollOnHashChanges,
    scrollOnSearchChanges,
    useOnMount,
    top,
    getComponentTop,
    scrollFunction,
    checkLocation
  } = options

  class ScrollTop extends React.Component {
    isUpdated = false
    prevMatch = this.props.match

    setChild = (child) => {
      if (this.child) {
        console.error('ScrollTop try set child when it already set')
      }
      this.child = child
    }

    removeChild = () => {
      if (!this.child) {
        console.error('ScrollTop try remove child when it already removed')
      }
      this.child = undefined
    }

    getTop = () => {
      if (!getComponentTop || !_has(this.component, 'getBoundingClientRect') || typeof window === 'undefined') {
        return top
      }
      return (getComponentTop && this.component.getBoundingClientRect().top + window.pageYOffset + top)
    }

    checkAndScroll = (prevLocation, pathChanges, hashChanges, searchChanges) => {
      const { prevMatch, isUpdated, props: { location, match } } = this
      if (
        (useOnMount || isUpdated) &&
        checkLocation({ location, match, prevLocation, prevMatch }) &&
        (!this.child || !this.child.checkAndScroll(prevLocation, pathChanges, hashChanges, searchChanges))
      ) {
        if (
          (scrollOnPathChanges && pathChanges) ||
          (scrollOnHashChanges && hashChanges) ||
          (scrollOnSearchChanges && searchChanges)
        ) {
          scrollFunction(this.getTop())
        }
        return true
      }
      return false
    }

    refComponent = (component) => {
      this.component = component
    }

    getChildContext () {
      return {
        scrollTopSet: this.setChild,
        scrollTopRemove: this.removeChild,
        scrollParent: true
      }
    }

    componentDidMount () {
      if (this.context.scrollTopSet) {
        this.context.scrollTopSet(this)
      }
    }

    componentDidUpdate (prevProps) {
      this.prevMatch = { ...prevProps.match }
      this.isUpdated = true
      if (this.context.scrollParent != null) return

      const pathChanges = prevProps.location.pathname !== this.props.location.pathname
      const hashChanges = prevProps.location.hash !== this.props.location.hash
      const searchChanges = prevProps.location.search !== this.props.location.search

      if (pathChanges || hashChanges || searchChanges) {
        this.checkAndScroll(prevProps.location, pathChanges, hashChanges, searchChanges)
      }
    }

    componentWillUnmount () {
      if (this.context.scrollTopRemove) {
        this.context.scrollTopRemove(this)
      }
    }

    render () {
      const element = <WrappedComponent {...this.props} />
      if (getComponentTop) {
        return (
          <div ref={this.refComponent}>
            {element}
          </div>
        )
      }
      return element
    }

    static contextTypes = contextTypes

    static childContextTypes = contextTypes
  }

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'
  ScrollTop.displayName = `scrollTop(${displayName})`

  const ScrollTopWithRouter = withRouter(hoistNonReactStatics(ScrollTop, WrappedComponent))
  ScrollTopWithRouter.ScrollTop = ScrollTop
  ScrollTopWithRouter.WrappedComponent = WrappedComponent
  ScrollTopWithRouter.options = options
  return ScrollTopWithRouter
}
