import { LOADING_DATA_END } from '../../index'
import _assign from 'lodash/assign'
import _cloneDeep from 'lodash/cloneDeep'
import _isArray from 'lodash/isArray'
import _isString from 'lodash/isString'
import _every from 'lodash/every'
import _partial from 'lodash/partial'
import _has from 'lodash/has'
import _isEqual from 'lodash/isEqual'
import _set from 'lodash/set'
import _omit from 'lodash/omit'
import _some from 'lodash/some'
import _get from 'lodash/get'

export const defaultOptions = {
  pathKey: 'exCollectorPath',
  changeableRequestKey: 'exCollectorChangeableRequest',
  unshiftKey: 'exCollectorUnshift'
}

/**
 * @typedef {Object} RemoteDataProvider_new_props
 * [en|added by middleware props, which can be used in [RemoteDataProvider](#remote-data-provider.RemoteDataProvider)]
 * [ru|добавляемые middleware props, которые могут использоваться в [RemoteDataProvider](#remote-data-provider.RemoteDataProvider)]
 * @param {Array<String>} [exCollectorChangeableRequest]
 * [en|array of `request` (from RemoteDataProvider [props](#remote-data-provider.RemoteDataProvider.props)) keys (deep keys can be joined by '.', like 'some.deep.key', or be array, like ['some', 'deep', 'key']), which don't join in check of equivalence of last and current `request`; if both `request` are equal, new `response` will be collect with last, otherwise, `response` doesn't change; if `exCollectorChangeableRequest` not defined, response data don't changes]
 * [ru|массив ключей в `request` (из RemoteDataProvider [props](#remote-data-provider.RemoteDataProvider.props)) (вложенные ключи могут объединяться '.' - 'some.deep.key', или быть массивом - ['some', 'deep', 'key']), которые не включаются в проверку эквивалентности прошлого и текущего `request`; если оба `request` эквивалентны, данные из нового и старого `response` собираются вместе, иначе `response` не меняется; если `exCollectorChangeableRequest` не задан, данные ответа никак не изменяются]
 * @param {Array<String>} [exCollectorPath]
 * [en|path to collect (Array, like ['some', 'collect', 'path']) data in `response` (from [action dispatch LOADING_DATA_END](#remote-data-provider.getData.LOADING_DATA_END)); if not defined - data collect directly in response]
 * [ru|путь к собираемым вместе данным (типа Array, например, ['some', 'collect', 'path']) в `response` (из [действия LOADING_DATA_END](#remote-data-provider.getData.LOADING_DATA_END); если не задан, данные собираются вместе прямо в `response`]
 * @param {Boolean} [exCollectorUnshift]
 * [en|if `true`, new data will be unshifted to old data, instead of pushed]
 * [ru|если задано в `true`, новый данные будут добавлены перед старыми, а не после]
 * @memberOf extensions.collector.collectorMiddleware
 * @example
// [[en|collectorMiddleware should be connected to redux][ru|collectorMiddleware должен быть подключен к redux]]
const props = {
 reducerKey: 'someKey',
 request: {
   url: '/api/users',
   page: 1,
   deepKey: {
     page: 1
   }
 },
 exCollectorChangeableRequest: [ 'page', 'deepKey.page' ],
 exCollectorPath: ['users', 'items'],
 // [[en|other RemoteDataProvider props][ru|остальные RemoteDataProvider props]]
}
<RemoteDataProvider {...props}>
 {({ response }) => console.log(response)}
</RemoteDataProvider>
// [[en|log in console:][ru|выведет в консоль:]]
{
 users: {
   items: [
     'some user1 from page 1',
     'some user2 from page 1'
   ],
   page: 1
 },
 // [[en|other response data][ru|остальные данные ответа]]
}
props.request.page = 2 // [[en|or][ru|или]]
props.request.deepKey.page = 2

<RemoteDataProvider {...props}>
 {({ response }) => console.log(response)}
</RemoteDataProvider>
// [[en|log in console:][ru|выведет в консоль:]]
{
 users: {
   items: [
     'some user1 from page 1',
     'some user2 from page 1',
     'some user3 from page 2',
     'some user4 from page 2'
   ],
   page: 2 // [[en|data out of a `exCollectorPath` key rewrite][ru|данные вне ключа `exCollectorPath` перезаписываются]]
 },
 // [[en|other data from new response][ru|остальные данные нового ответа]]
}

// [[en|can use with not only array data][ru|можно использовать не только с массивами]]
props.exCollectorPath = 'users'
props.request.page = 3

<RemoteDataProvider {...props}>
 {({ response }) => console.log(response)}
</RemoteDataProvider>
// [[en|log in console:][ru|выведет в консоль:]]
{
 users: [ // [[en|if data in `exCollectorPath` key not an array, it wraps to array][ru|если данные по ключу `exCollectorPath` не массив, они оборачиваются в массив]]
   { // [[en|last response][ru|прошлый запрос]]
     items: [
       'some user1 from page 1',
       'some user2 from page 1',
       'some user3 from page 2',
       'some user4 from page 2'
     ],
     page: 2
   }
   { // [[en|current response][ru|текущий запрос]]
     items: [
       'some user5 from page 3',
       'some user6 from page 3'
     ],
     page: 3
   }
 ],
 // [[en|other data from new response][ru|остальные данные нового ответа]]
}

// [[en|use exCollectorUnshift][ru|использование exCollectorUnshift]]
props.request.page = 4
props.exCollectorUnshift = true

<RemoteDataProvider {...props}>
 {({ response }) => console.log(response)}
</RemoteDataProvider>
// [[en|log in console:][ru|выведет в консоль:]]
{
 users: [
   {// [[en|current response, unshifted][ru|текущий запрос, добавленный в начало]]
     items: [
       'some user5 from page 4',
       'some user6 from page 4'
     ],
     page: 4
   }
   { // [[en|last response][ru|прошлый запрос]]
     items: [
       'some user1 from page 1',
       'some user2 from page 1',
       'some user3 from page 2',
       'some user4 from page 2'
     ],
     page: 2
   }
   { // [[en|last response][ru|прошлый запрос]]
     items: [
       'some user5 from page 3',
       'some user6 from page 3'
     ],
     page: 3
   }
 ],
 // [[en|other data from new response][ru|остальные данные нового ответа]]
}
 */

/**
 * @desc
 * [en|redux middleware which collect loaded data from [RemoteDataProvider](#remote-data-provider.RemoteDataProvider), if to it are set parameters are necessary]
 * [ru|redux middleware которое собирает загруженные данные от [RemoteDataProvider](#remote-data-provider.RemoteDataProvider), если ему заданы необходимые props]
 * @param {Object} [options]
 * [en|usually, `options` need to be set only if there are crossings with other props keys]
 * [ru|обычно `options` нужно задавать только если имеются пересечения в названиях props]
 * @param {String} [options.pathKey='exCollectorPath']
 * [en|if defined, change key for RemoteDataProvider [`exCollectorPath`](extensions.collector.collectorMiddleware.RemoteDataProvider_new_props) prop]
 * [ru|если задан, меняет ключ для RemoteDataProvider [`exCollectorPath`](extensions.collector.collectorMiddleware.RemoteDataProvider_new_props) prop]
 * @param {String} [options.changeableRequestKey='exCollectorChangeableRequest']
 * [en|if defined, change key for RemoteDataProvider [`exCollectorChangeableRequest`](extensions.collector.collectorMiddleware.RemoteDataProvider_new_props) prop]
 * [ru|если задан, меняет ключ для RemoteDataProvider [`exCollectorChangeableRequest`](extensions.collector.collectorMiddleware.RemoteDataProvider_new_props) prop]
 * @param {String} [options.unshiftKey='exCollectorUnshift']
 * [en|if defined, change key for RemoteDataProvider [`exCollectorUnshift`](extensions.collector.collectorMiddleware.RemoteDataProvider_new_props) prop]
 * [ru|если задан, меняет ключ для RemoteDataProvider [`exCollectorUnshift`](extensions.collector.collectorMiddleware.RemoteDataProvider_new_props) prop]
 * @returns {Function} middleware
 * @memberOf extensions.collector
 * @example
import { collectorMiddleware } from 'remote-data-provider/extensions/collector'

const store = compose(
 applyMiddleware(collectorMiddleware()),
 // [[en|other middlewares][ru|остальные middlewares]]
)(createStore)(rootReducer, initialState)

// [[en|with custom keys for RemoteDataProvider props][ru|с измененными ключами RemoteDataProvider props]]
const store = compose(
  applyMiddleware(
    collectorMiddleware({
     pathKey: 'justPath',
     changeableRequestKey: 'justChangeable'
    })
  ),
// [[en|other middlewares][ru|остальные middlewares]]
)(createStore)(rootReducer, initialState)

// [[en|further in a code][ru|далее в коде]]

<RemoteDataProvider
 justPath={['some', 'path']}
 justChangeable={['page', 'deepKey.page']}
/>
 */
function collectorMiddleware (options = {}) {
  const { pathKey, changeableRequestKey, unshiftKey } = _assign({}, defaultOptions, options)
  return store => next => action => {
    if (
      anotherActionType(action) ||
      hasNotRequiredKeys(action, changeableRequestKey)
    ) {
      return next(action)
    }
    const newAction = _cloneDeep(action)
    const propsPath = _get(newAction, ['payload', pathKey])
    const path = _isArray(propsPath)
      ? propsPath
      : (_isString(propsPath) ? propsPath.split('.') : [])
    const unshift = _get(newAction, ['payload', unshiftKey])
    const response = getFromPath(_get(newAction, ['payload', 'response']), path)
    const localResponse = getFromPath(_get(newAction, ['payload', 'localState', 'response']), path)
    const composedResponse = unshift ? [].concat(response, localResponse) : [].concat(localResponse, response)
    if (
      localStateIsEmpty(newAction) ||
      requestsAreNotEqual(newAction, changeableRequestKey)
    ) {
      _set(newAction, [].concat('payload', 'response', path), [].concat(response))
    } else {
      _set(newAction, [].concat('payload', 'response', path), composedResponse)
    }
    return next(newAction)
  }
}

export { collectorMiddleware }

export function anotherActionType (action) {
  return _get(action, 'type') !== LOADING_DATA_END
}

export function hasNotRequiredKeys (action, changeableRequestKey) {
  return !_every(
    [changeableRequestKey, 'localState', 'request', 'response'],
    _partial(_has, _get(action, 'payload'))
  )
}

export function localStateIsEmpty (action) {
  return _get(action, ['payload', 'localState', 'isEmpty'], true)
}

export function requestsAreNotEqual (action, changeableRequestKey) {
  const omitKeys = _get(action, ['payload', changeableRequestKey])
  return !_isEqual(
    _omit(_get(action, ['payload', 'localState', 'request']), omitKeys),
    _omit(_get(action, ['payload', 'request']), omitKeys)
  )
}

export function getFromPath (data, path) {
  return _some(path) ? _get(data, path) : data
}
