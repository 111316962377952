import { LOADING_DATA_START, LOADING_DATA_END, LOADING_DATA_ERROR } from '../../index'
import stringify from 'qs/lib/stringify'
import _includes from 'lodash/includes'
import _has from 'lodash/has'
import _pick from 'lodash/pick'
import _get from 'lodash/get'

export function asyncActionMiddleware (asyncActionController) {
  return store => next => action => {
    if (
      !_includes([LOADING_DATA_START, LOADING_DATA_END, LOADING_DATA_ERROR], action.type) ||
      !_has(action, 'payload.request') ||
      !_has(action, 'payload.reducerKey')
    ) {
      return next(action)
    }
    const actionKey = stringify(_pick(action.payload, ['reducerKey', 'reducerPath', 'request']))
    if (action.type === LOADING_DATA_START) {
      asyncActionController.startAction(actionKey)
    }
    const result = next(action)
    if (action.type === LOADING_DATA_END) {
      asyncActionController.completeAction(actionKey)
    } else if (action.type === LOADING_DATA_ERROR) {
      asyncActionController.completeAction(actionKey, _get(action, 'payload.error'))
    }
    return result
  }
}
