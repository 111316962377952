import cleanSet from 'clean-set'
import {
  LOADING_DATA_START,
  LOADING_DATA_END,
  LOADING_DATA_ERROR,
  RUN_FUNCTION_WITH_STATE, CLEAR_DATA
} from './constants'
import _isEqual from 'lodash/isEqual'
import _get from 'lodash/get'
import _unset from 'lodash/unset'

/**
 * @desc
 * [en|RemoteDataProvider stores data of requests in redux]
 * [ru|RemoteDataProvider хранит данные о запросах в redux]
 * @namespace remote-data-provider.redux
 * @category info
 */

/**
 * @typedef {Object} localState
 * @desc
 * [en|data format for each request, which is stored in redux]
 * [ru|формат данных для каждого запроса, хранящегося в redux]
 * @param {Object} [response]
 * [en|received data from server response]
 * [ru|полученные данные из ответа сервера]
 * @param {Object} [request]
 * [en|similar, as in RemoteDataProvider [props](#remote-data-provider.RemoteDataProvider.props)]
 * [ru|такой же, как в RemoteDataProvider [props](#remote-data-provider.RemoteDataProvider.props)]
 * @param {Boolean} isEmpty
 * [en|it is `true` when `response` are empty (data aren't loaded or loaded with error)]
 * [ru|устанавливается в `true` если в `response` ничего нет (данные не загружены или загружены с ошибкой)]
 * @param {Boolean} isAjax
 * [en|it is `true` when data loading (at this time in `response` can be some old loaded data)]
 * [ru|устанавливается в `true` пока данные загружаются (в это время в `response` могут быть старые загруженные данные)]
 * @param {Boolean} isError
 * [en|it is `true` when data loaded with error (`response` is established to `undefined`)]
 * [ru|устанавливается в `true` при ошибке загрузки данных (`response` устанавливается в `undefined`)]
 * @param {Object} [error]
 * [en|contain `message`, `status` and `data` params from response, when data loaded with error (`isError` to be `true`)]
 * [ru|содержит параметры `message`, `status` и `data` из ответа, когда данные загружены с ошибкой (`isError` равен `true`)]
 * @default
 * {
 *   response: undefined,
 *   request: undefined,
 *   isEmpty: true,
 *   isAjax: false,
 *   isError: false,
 *   error: undefined
 * }
 * @memberOf remote-data-provider.redux
 */

/**
 * @typedef {remote-data-provider.redux.localState} remoteDataState
 * @extends remote-data-provider.redux.localState
 * @param {function (): Promise<Object>} reload
 * [en|reload data with last `request`]
 * [ru|перезагружает данные с последним `request`]
 * @param {function (): Object} clear
 * [en|clear data in redux]
 * [ru|очищает данные в redux]
 * @memberOf remote-data-provider
 * @category info
 */

const initialLocalState = {
  providerId: undefined,
  response: undefined,
  request: undefined,
  isEmpty: true,
  isAjax: false,
  isError: false,
  error: undefined
}

export { initialLocalState }

/**
 * @typedef {Object} state
 * @desc
 * [en|remote-data-provider redux state is plain object]
 * [ru|remote-data-provider `state` в redux обычный объект]
 * @default {}
 * @memberOf remote-data-provider.redux
 * @example
// [[en|get 'data' key from remote-data-provider store, connected to 'remoteData'][ru|получаем данные по ключу 'data' из remote-data-provider стора, подключенного по ключу 'remoteData']]
const data = store.getState().remoteData.data
 */

let reducerName = 'remoteData'

/**
 * @desc
 * [en|returns current key on which the reducer is connected]
 * [ru|возвращает текущий ключ, по которому подключен редьюсер]
 * @returns {string}
 * @default 'remoteData'
 * @memberOf remote-data-provider
 * @category utils
 * @example
// get current remote-data-provider state
import { getReducerName } from 'remote-data-provider'

store.getState()[getReducerName()]
 */
function getReducerName () {
  return reducerName
}

export { getReducerName }

function setReducerName (name) {
  reducerName = name
}

export { setReducerName }

/**
 * @desc
 * [en|returns reducer and set key on which the reducer is connected]
 * [ru|возвращает редьюсер и устанавливает ключ, по которому подключен редьюсер]
 * @param {String} name [en|key on which the reducer is connected][ru|ключ, по которому подключен редьюсер]
 * @returns {remote-data-provider.reducer} reducer
 * @memberOf remote-data-provider
 * @category reducers
 * @example
import { getReducerWithName } from 'remote-data-provider'

rootReducer = combineReducers({
  dataStore: getReducerWithName('dataStore'),
  // ...[[en|other reducers][ru|остальные редьюсеры]]
})
 */
function getReducerWithName (name) {
  setReducerName(name)
  return reducer
}

export { getReducerWithName }

/**
 * @desc
 * [en|reducer for redux, should to be connected with key 'remoteData', otherwise use [getReducerWithName](#remote-data-provider.redux.getReducerWithName)]
 * [ru|редьюсер для redux, должен подключаться по ключу 'remoteData', иначе необходимо использовать [getReducerWithName](#remote-data-provider.redux.getReducerWithName)]
 * @memberOf remote-data-provider
 * @category reducers
 * @example
import { reducer } from 'remote-data-provider'

rootReducer = combineReducers({
  remoteData: reducer,
  // ...[[en|other reducers][ru|остальные редьюсеры]]
}
 */
function reducer (state = {}, action) {
  switch (action.type) {
    /**
     * @typedef {Object} LOADING_DATA_START
     * @desc
     * [en|set to state in `key`: `isError`, `error` from [localState](#remote-data-provider.redux.localState) defaults, `isAjax` as `true` and `request` from `payload`; don't changes `isEmpty` and `response`, see [LOADING_DATA_START in action](#remote-data-provider.getData.LOADING_DATA_START)]
     * [ru|Устанавливает в state по ключу `key` значения: `isError`, `error` из [localState](#remote-data-provider.redux.localState) defaults, `isAjax` в `true` и `request` из payload; не меняет `response` и `isEmpty`, см. [LOADING_DATA_START в action](#remote-data-provider.getData.LOADING_DATA_START)]
     * @param {remote-data-provider.LOADING_DATA_START} type [en|action type][ru|тип действия]
     * @param {Array<String>} key
     * [en|array of keys on which has to be [localState](#remote-data-provider.redux.localState) data]
     * [ru|массив ключей по которым в redux должны быть [localState](#remote-data-provider.redux.localState) данные]
     * @param {Object} payload
     * @param {Object} payload.request
     *
     * @memberOf remote-data-provider.reducer
     * @category reducer actions
     * @example
import { LOADING_DATA_START } from 'remote-data-provider'
// [[en|store state before dispatch:][ru|состояние store до выполнения dispatch:]]
{
  remoteData: {} // 'remoteData' [[en|reducer name as default, can be changed][ru|наименование редьюсера по умолчанию, изменяемое]]
}
// dispatch
store.dispatch({
  type: LOADING_DATA_START,
  key: ['some', 'key'],
  payload: {
    request: {
      url: 'your/api/method'
      params: {
        some: 'param'
      }
    },
    // [[en|other properties which are not used by action][ru|другие не используемые действием свойства]]
  }
})
// [[en|store state after dispatch:][ru|состояние store после выполнения dispatch:]]
{
  remoteData: {
    some: {
      key: {
        response: undefined,
        request: {
          url: 'your/api/method'
          params: {
            some: 'param'
          }
        },
        isEmpty: true,
        isAjax: true,
        isError: false,
        error: undefined
      }
    }
  }
}
     */
    case LOADING_DATA_START: {
      return cleanSet(
        state,
        action.key,
        currentState => {
          let localState = (
            currentState && currentState.hasOwnProperty('response') && currentState.hasOwnProperty('isEmpty')
          ) ? currentState : initialLocalState

          return {
            ...currentState,
            providerId: action.payload.providerId,
            response: localState.response,
            request: { ...action.payload.request },
            error: undefined,
            isEmpty: localState.isEmpty,
            isAjax: true,
            isError: false
          }
        }
      )
    }

    /**
     * @typedef {Object} LOADING_DATA_END
     * @desc
     * [en|set to state in `key`: `isError`, `error`, `isAjax` from [localState](#remote-data-provider.redux.localState) defaults, `isEmpty` as `false`, `request` and `response` from `payload`, see [LOADING_DATA_END in action](#remote-data-provider.getData.LOADING_DATA_END); if last `request` exist, and it not equal new `request`, state don't changes]
     * [ru|устанавливает в state по ключу `key` значения: `isError`, `error`, `isAjax` из [localState](#remote-data-provider.redux.localState) defaults, `isEmpty` в `false`, `request` и `response` из `payload`, см. [LOADING_DATA_END в action](#remote-data-provider.getData.LOADING_DATA_END); если имеется предыдущий `request`, и он не совпадает с текущим, state не изменится]
     * @param {remote-data-provider.LOADING_DATA_END} type [en|action type][ru|тип действия]
     * @param {Array<String>} key
     * [en|array of keys on which has to be [localState](#remote-data-provider.redux.localState) data]
     * [ru|массив ключей по которым в redux должны быть [localState](#remote-data-provider.redux.localState) данные]
     * @param {Object} payload
     * @param {Object} payload.request
     * @param {Any} payload.response
     *
     * @memberOf remote-data-provider.reducer
     * @category reducer actions
     * @example
import { LOADING_DATA_END } from 'remote-data-provider'
// [[en|store state before dispatch:][ru|состояние store до выполнения dispatch:]]
{
 remoteData: {} // 'remoteData' [[en|reducer name as default, can be changed][ru|наименование редьюсера по умолчанию, изменяемое]]
}
// dispatch
store.dispatch({
 type: LOADING_DATA_END,
 key: ['some', 'key'],
 payload: {
   request: {
     url: 'your/api/method'
     params: {
       some: 'param'
     }
   },
   response: {
     data: {
       some: 'data'
     }
   },
   // [[en|other properties which are not used by action][ru|другие не используемые действием свойства]]
 }
})
// [[en|store state after dispatch:][ru|состояние store после выполнения dispatch:]]
{
 remoteData: {
   some: {
     key: {
       response: {
         data: {
           some: 'data'
         },
       },
       request: {
         url: 'your/api/method'
         params: {
           some: 'param'
         }
       },
       isEmpty: false,
       isAjax: false,
       isError: false,
       error: undefined
     }
   }
 }
}
     */
    case LOADING_DATA_END: {
      const currentRequest = _get(state, [...action.key, 'request'])
      if (currentRequest && !_isEqual(currentRequest, action.payload.request)) {
        return state
      }
      return cleanSet(
        state,
        action.key,
        currentState => ({
          ...currentState,
          providerId: action.payload.providerId,
          response: action.payload.response,
          request: currentRequest || { ...action.payload.request },
          error: undefined,
          isError: false,
          isAjax: false,
          isEmpty: false
        })
      )
    }

    /**
     * @typedef {Object} LOADING_DATA_ERROR
     * @desc
     * [en|set to state in `key`: `isEmpty`, `response`, `isAjax` from [localState](#remote-data-provider.redux.localState) defaults, `isError` as `false`, `request` and `error` from `payload`, see [LOADING_DATA_ERROR in action](#remote-data-provider.getData.LOADING_DATA_ERROR); if last `request` exist, and it not equal new `request`, state don't changes]
     * [ru|устанавливает в state по ключу `key` значения: `isError`, `error`, `isAjax` из [localState](#remote-data-provider.redux.localState) defaults, `isEmpty` в `false`, `request` и `response` из `payload`, см. [LOADING_DATA_ERROR в action](#remote-data-provider.getData.LOADING_DATA_ERROR); если имеется предыдущий `request`, и он не совпадает с текущим, state не изменится]
     * @param {remote-data-provider.LOADING_DATA_ERROR} type [en|action type][ru|тип действия]
     * @param {Array<String>} key
     * [en|array of keys on which has to be [localState](#remote-data-provider.redux.localState) data]
     * [ru|массив ключей по которым в redux должны быть [localState](#remote-data-provider.redux.localState) данные]
     * @param {Object} payload
     * @param {Object} payload.request
     * @param {Any} payload.error
     *
     * @memberOf remote-data-provider.reducer
     * @category reducer actions
     * @example
import { LOADING_DATA_ERROR } from 'remote-data-provider'
// [[en|store state before dispatch:][ru|состояние store до выполнения dispatch:]]
{
  remoteData: {
    some: {
      key: {
        response: {
          data: {
            some: 'data'
          },
        },
        request: {
          url: 'your/api/method'
          params: {
            some: 'param'
          }
        },
        isEmpty: false,
        isAjax: false,
        isError: false,
        error: undefined
      }
    }
  }
}
// dispatch
store.dispatch({
  type: LOADING_DATA_ERROR,
  key: ['some', 'key'],
  payload: {
    request: {
      url: 'your/api/method'
      params: {
        some: 'param'
      }
    },
    error: {
      reason: {
        some: 'reason'
      },
      status: 500
    },
    // [[en|other properties which are not used by action][ru|другие не используемые действием свойства]]
  }
})
// [[en|store state after dispatch:][ru|состояние store после выполнения dispatch:]]
{
  remoteData: {
    some: {
      key: {
        response: undefined
        request: {
          url: 'your/api/method'
          params: {
            some: 'param'
          }
        },
        isEmpty: true,
        isAjax: false,
        isError: true,
        error: undefined
      }
    }
  }
}
     */
    case LOADING_DATA_ERROR: {
      const currentRequest = _get(state, [...action.key, 'request'])
      if (currentRequest && !_isEqual(currentRequest, action.payload.request)) {
        return state
      }
      return cleanSet(
        state,
        action.key,
        currentState => ({
          ...currentState,
          providerId: action.payload.providerId,
          response: undefined,
          request: currentRequest || { ...action.payload.request },
          error: action.payload.error,
          isEmpty: true,
          isAjax: false,
          isError: true
        })
      )
    }

    /**
     * @typedef {Object} CLEAR_DATA
     * @desc
     * [en|remove state data in `key`]
     * [ru|удаляет данные из state по ключу `key`]
     * @param {remote-data-provider.CLEAR_DATA} type
     * [en|action type]
     * [ru|тип действия]
     * @param {Array<String>} key
     * [en|array of keys on which has to be [localState](#remote-data-provider.redux.localState) data]
     * [ru|массив ключей по которым в redux должны быть [localState](#remote-data-provider.redux.localState) данные]
     * @memberOf remote-data-provider.reducer
     * @category reducer actions
     * @example
import { CLEAR_DATA } from 'remote-data-provider'
// [[en|store state before dispatch:][ru|состояние store до выполнения dispatch:]]
{
  remoteData: {
    some: {
      key: {
        response: {
          data: {
            some: 'data'
          },
        },
        request: {
          url: 'your/api/method'
          params: {
            some: 'param'
          }
        },
        isEmpty: false,
        isAjax: false,
        isError: false,
        error: undefined
      }
    }
  }
}
// dispatch
store.dispatch({
  type: CLEAR_DATA,
  key: ['some', 'key']
})
// [[en|store state after dispatch:][ru|состояние store после выполнения dispatch:]]
{
  remoteData: {
    some: {}
  }
}
     */
    case CLEAR_DATA:
      const nextState = cleanSet(state, action.key, '')
      _unset(nextState, action.key)
      return nextState

    /**
     * @typedef {Object} RUN_FUNCTION_WITH_STATE
     * @desc
     * [en|call synchronise function with `state`, which should to returns new `state`]
     * [ru|вызывает синхронную функцию с аргументом `state`, которая должна вернуть новый `state`]
     * @param {remote-data-provider.RUN_FUNCTION_WITH_STATE} type
     * [en|run custom function action type]
     * [ru|тип действия - запуск функции со state]
     * @param {function(Object): Object} payload
     * [en|function which call with state arg an should return new state]
     * [ru|функция, которая вызывается с аргументом state и должна вернуть новый state]
     * @memberOf remote-data-provider.reducer
     * @category reducer actions
     * @example
import { RUN_FUNCTION_WITH_STATE } from 'remote-data-provider'
// [[en|store state before dispatch:][ru|состояние store до выполнения dispatch:]]
{
 remoteData: {} // 'remoteData' [[en|reducer name as default, can be changed][ru|наименование редьюсера по умолчанию, изменяемое]]
}
// dispatch
store.dispatch({
 type: RUN_FUNCTION_WITH_STATE,
 payload: function (state) {
  return { ...state, someData: 'data' }
 }
})
// [[en|store state after dispatch:][ru|состояние store после выполнения dispatch:]]
{
 remoteData: {
   someData: 'data'
 }
}
     */
    case RUN_FUNCTION_WITH_STATE:
      if (typeof action.payload === 'function') {
        return action.payload(state)
      }
      return state

    default:
      return state
  }
}

export { reducer }
