/**
 * [en|Type for start loading data redux action][ru|Тип redux действия для начала загрузки данных]
 * @type {'REMOTE_DATA_PROVIDER_LOADING_DATA_START'} LOADING_DATA_START
 * @memberOf remote-data-provider
 * @category constants
 */
const LOADING_DATA_START = 'REMOTE_DATA_PROVIDER_LOADING_DATA_START'
export { LOADING_DATA_START }

/**
 * [en|Type for end loading redux action][ru|Тип redux действия для окончания загрузки данных]
 * @type {'REMOTE_DATA_PROVIDER_LOADING_DATA_END'} LOADING_DATA_END
 * @memberOf remote-data-provider
 * @category constants
 */
const LOADING_DATA_END = 'REMOTE_DATA_PROVIDER_LOADING_DATA_END'
export { LOADING_DATA_END }

/**
 * [en|Type for error loading redux action][ru|Тип redux действия для ошибки загрузки данных]
 * @type {'REMOTE_DATA_PROVIDER_LOADING_DATA_ERROR'} LOADING_DATA_ERROR
 * @memberOf remote-data-provider
 * @category constants
 */
const LOADING_DATA_ERROR = 'REMOTE_DATA_PROVIDER_LOADING_DATA_ERROR'
export { LOADING_DATA_ERROR }

/**
 * [en|Type for clear data in redux][ru|Тип redux действия для удаления данных]
 * @type {'REMOTE_DATA_PROVIDER_CLEAR_DATA'} CLEAR_DATA
 * @memberOf remote-data-provider
 * @category constants
 */
const CLEAR_DATA = 'REMOTE_DATA_PROVIDER_CLEAR_DATA'
export { CLEAR_DATA }

/**
 * [en|Type for run function with state][ru|Тип redux действия для вызова функции со state]
 * @type {'REMOTE_DATA_PROVIDER_RUN_FUNCTION_WITH_STATE'} RUN_FUNCTION_WITH_STATE
 * @memberOf remote-data-provider
 * @category constants
 */
const RUN_FUNCTION_WITH_STATE = 'REMOTE_DATA_PROVIDER_RUN_FUNCTION_WITH_STATE'
export { RUN_FUNCTION_WITH_STATE }
